import BlogIcon from "!raw-loader!./sections/blog.svg"
import DocsIcon from "!raw-loader!./sections/docs.svg"
import TutorialIcon from "!raw-loader!./sections/tutorial.svg"
import PluginsIcon from "!raw-loader!./sections/plugins.svg"
import ShowcaseIcon from "!raw-loader!./sections/showcase.svg"
import EcosystemIcon from "!raw-loader!./sections/ecosystem.svg"

export {
  BlogIcon,
  DocsIcon,
  TutorialIcon,
  PluginsIcon,
  ShowcaseIcon,
  EcosystemIcon,
}
