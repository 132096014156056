import React from "react"
import styled from "react-emotion"

import presets, { colors } from "../../utils/presets"
import { rhythm, options } from "../../utils/typography"

const FooterContainer = styled("footer")(props => ({
  display: `flex`,
  alignItems: `center`,
  flexDirection: `column`,
  justifyContent: `center`,
  height: presets.footerHeight,

  backgroundColor: props.isHomepage ? colors.gatsby : `white`,
  color: `white`,
}))

const ICP = styled(`div`)`
  font-size: 0.6em;
`

const Copyright = styled(`div`)`
  font-size: 0.6em;
`

const Footer = ({ pathname }) => {
  const isHomepage = pathname === `/`

  return (
    <FooterContainer isHomepage={isHomepage}>
      <Copyright>
        © 2015 xllg.com, all rights reserved 北京祥龙物流（集团）有限公司
        版权所有
      </Copyright>
      <ICP>京ICP备15043796号</ICP>
    </FooterContainer>
  )
}
export default Footer
