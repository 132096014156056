import { Link } from "gatsby"
import React from "react"
import styled from "react-emotion"
import presets, { colors } from "../../utils/presets"
import typography, { rhythm, scale } from "../../utils/typography"
import Logo from "../logo"
import SearchForm from "../search-form"

// what we need to nudge down the navItems to sit
// on the baseline of the logo's wordmark
const navItemHorizontalSpacing = rhythm(1 / 3)

const iconColor = colors.lilac
const iconColorHomepage = colors.ui.light

const assignActiveStyles = ({ isPartiallyCurrent }) =>
  isPartiallyCurrent ? { style: styles.navItem.active } : {}

const NavItem = ({ linkTo, children }) => (
  <li css={styles.li}>
    <Link to={linkTo} getProps={assignActiveStyles} css={styles.navItem}>
      {children}
    </Link>
  </li>
)

const HeaderElement = styled("header")(props => ({
  backgroundColor: props.isHomepage ? `transparent` : `rgba(255,255,255,975)`,
  position: `relative`,
  float: props.isHomepage ? `left` : `none`,
  width: "100%",
  left: 0,
  right: 0,
  height: props.isHomepage
    ? presets.headerHeightHomePage
    : presets.headerHeight,
  zIndex: 2,
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: props.isHomepage ? `transparent` : colors.ui.border,

  display: "flex",
  alignItems: "center",
  justifyContent: `space-between`,
  paddingLeft: rhythm(2),
  paddingRight: rhythm(2),
}))

const MenuContainer = styled("div")({
  height: `100%`,
  display: "flex",
  alignItems: "center",
})

const NavUl = styled("ul")({
  display: `none`,
  [presets.Tablet]: {
    flexGrow: 1,
    overflowX: `visible`,
    marginLeft: rhythm(1 / 4),
    listStyle: `none`,

    display: `flex`,
    alignItems: "center",
  },
})

const styles = {
  li: {
    display: `block`,
    margin: 0,
    marginLeft: navItemHorizontalSpacing,
    marginRight: navItemHorizontalSpacing,
    whiteSpace: `nowrap`,
  },
  // link style
  navItem: {
    display: `block`,
    fontSize: `0.85rem`,
    lineHeight: `calc(${presets.headerHeight} - ${
      presets.navItemBottomMargin
    })`,

    textDecoration: `none`,
    borderBottom: `0.125rem solid transparent`,
    "&:hover": {
      color: colors.gatsby,
    },
    active: {
      borderBottomColor: colors.gatsby,
      color: colors.gatsby,
    },
    zIndex: 1,
    color: `inherit`,
    transition: `color ${presets.animation.speedDefault} ${
      presets.animation.curveDefault
    }`,
    WebkitFontSmoothing: `antialiased`,
  },
}

const SearchContainer = styled("div")({})

const Header = ({ pathname }) => {
  const isHomepage = pathname === `/`
  const isBlog = pathname === `/blog/` || pathname.indexOf(`/blog/page/`) === 0

  return (
    <HeaderElement isHomepage={isHomepage}>
      <MenuContainer>
        <Logo />

        <NavUl>
          <NavItem linkTo="/docs/">企业介绍</NavItem>
          <NavItem linkTo="/features/">业务领域</NavItem>
          <NavItem linkTo="/blog/">新闻动态</NavItem>
          <NavItem linkTo="/sub/">下属单位</NavItem>
          <NavItem linkTo="/contact/">联系我们</NavItem>
        </NavUl>
      </MenuContainer>

      <SearchContainer>
        <SearchForm
          key="SearchForm"
          iconColor={isHomepage ? iconColorHomepage : iconColor}
          isHomepage={isHomepage}
          offsetVertical="-0.2175rem"
        />
      </SearchContainer>
    </HeaderElement>
  )
}

export default Header
