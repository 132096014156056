import React from "react"
import { Link } from "gatsby"
import styled from "react-emotion"
import presets, { colors } from "../utils/presets"
import { rhythm } from "../utils/typography"
import logo from "../logo.svg"

const LogoLink = styled(Link)({
  marginRight: rhythm(1 / 2),
  textDecoration: `none`,
  height: `70%`,
})

const LogoImg = styled("img")({
  height: `100%`,
  margin: 0,
})

const Logo = () => {
  return (
    <LogoLink to="/" aria-label="祥龙物流, 返回首页">
      <LogoImg src={logo} alt="XLLG Logo" aria-hidden="true" />
    </LogoLink>
  )
}

export default Logo
