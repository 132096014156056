// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-template-blog-list-js": () => import("/opt/build/repo/src/templates/template-blog-list.js" /* webpackChunkName: "component---src-templates-template-blog-list-js" */),
  "component---src-templates-template-blog-post-js": () => import("/opt/build/repo/src/templates/template-blog-post.js" /* webpackChunkName: "component---src-templates-template-blog-post-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-template-contributor-page-js": () => import("/opt/build/repo/src/templates/template-contributor-page.js" /* webpackChunkName: "component---src-templates-template-contributor-page-js" */),
  "component---src-templates-template-docs-markdown-js": () => import("/opt/build/repo/src/templates/template-docs-markdown.js" /* webpackChunkName: "component---src-templates-template-docs-markdown-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-tags-js": () => import("/opt/build/repo/src/pages/blog/tags.js" /* webpackChunkName: "component---src-pages-blog-tags-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-docs-company-chart-js": () => import("/opt/build/repo/src/pages/docs/company-chart.js" /* webpackChunkName: "component---src-pages-docs-company-chart-js" */),
  "component---src-pages-docs-index-js": () => import("/opt/build/repo/src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-features-js": () => import("/opt/build/repo/src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("/opt/build/repo/src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-sub-js": () => import("/opt/build/repo/src/pages/sub.js" /* webpackChunkName: "component---src-pages-sub-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

